class I18n {
  language = 'el'
  languageChangeFns = []
  translations = {
    en: {
      logout: "Logout",
      welcome: "Welcome",
      youre_reaching_your_goal: "You are reaching your goal",
      view_sales: 'View Sales',
      statistics: 'Statistics',
      sales: "Sales",
      products: 'Products',
      customers: 'Customers',
      profit: 'Profit',
      updated_1_day_ago: 'Updated 1 day ago',
      orders: "Orders",
      successful_engagements: 'Successful Engagements',
      visitors: 'Visitors',
      profit_overview: 'Profit Overview',
      income: 'Income',
      expenses: 'Expenses',
      name_table_heading: 'Name',
      address_table_heading: 'Address',
      phone_table_heading: 'Phone',
      return_to_store_table_heading: 'Return',
      this_month: 'This month',
      more_than_october: 'more than October'
    },
    el: {
      logout: "Αποσύνδεση",
      welcome: "Καλως ήρθατε",
      youre_reaching_your_goal: "Πλησιάζετε στον στόχο σας",
      view_sales: 'Δείτε της πωλήσεις',
      statistics: 'Στατιστικά',
      sales: "Πωλήσεις",
      products: 'Προϊόντα',
      customers: 'Πελάτες',
      profit: 'Κέρδος',
      updated_1_day_ago: 'Ενημερώθηκε πριν 1 ημέρα',
      orders: "Παραγγελίες",
      successful_engagements: 'Ολοκληρωμένες Επισκέψεις',
      visitors: 'Επισκέπτες',
      profit_overview: 'Αναφορά Κερδών',
      income: 'Έσοδα',
      expenses: 'Έξοδα',
      name_table_heading: 'ΟΝΟΜΑ',
      address_table_heading: 'ΔΙΕΥΘΥΝΣΗ',
      phone_table_heading: 'ΤΗΛΕΦΩΝΟ',
      return_to_store_table_heading: 'ΕΠΙΣΤΡΟΦΗ ΣΤΟ ΚΑΤΑΣΤΗΜΑ',
      this_month: 'Αυτό το μήνα',
      more_than_october: 'παραπάνω από τον Οκτώβριο'
    }
  }

  setLanguage(languageCode) {
    this.language = languageCode
    this.languageChangeFns.forEach((fn) => {
      fn()
    })
  }

  onLanguageChange(fn) {
    this.languageChangeFns.push(fn)

    return () => {
      this.languageChangeFns.splice(this.languageChangeFns.indexOf(fn), 1)
    }
  }

  t(key, languageCode) {
    return this.translations[languageCode || this.language][key]
  }
}

const i18n = new I18n()
window.i18n = i18n

export default i18n